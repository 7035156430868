import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Layout from "../components/global-layout"
import Headings from "../components/mdx/headings"
import SEO from "../components/seo"

const PostList = styled.ul`
  margin-top: 3rem;
  padding: 0;
  list-style-type: decimal-leading-zero;
  list-style-position: inside;
  font-size: 14px;
  line-height: 1.5;
`

const PostListItem = styled.li`
  &:hover {
    background-color: var(--off-dark);
    color: var(--alert-yellow);
    font-weight: bold;
  }
`

const PostLink = styled(props => <Link {...props} />)`
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  text-decoration-line: none;
  &:link {
    color: var(--off-dark);
  }
  &:visited {
    color: var(--off-dark);
  }
`

const BlogIndex = ({ data }) => {
  const { edges: posts } = data.allMdx
  return (
    <Layout>
      <SEO title="posts" />
      <Headings.h1>All posts</Headings.h1>
      <PostList>
        {posts.map(({ node: post }) => (
          <PostLink 
            to={post.fields.slug}
            key={post.id}
          >
            <PostListItem>
              {post.frontmatter.title}
            </PostListItem>
          </PostLink>
        ))}
      </PostList>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogIndex {
    allMdx(
      filter: {
        frontmatter: {
          isblog: { eq: true }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            isblog
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default BlogIndex
